<template>
  <div class="pointConfig">
    <el-scrollbar>
      <el-tabs type="border-card">
        <el-tab-pane label="沉降监测">
          <MyTable :dataSource="dataSourceV" index stripe :columns="columnsV" border :loading="loadingV"></MyTable>
        </el-tab-pane>
        <el-tab-pane label="轴力监测">
          <MyTable :dataSource="dataSourceF" index stripe :columns="columnsF" border :loading="loadingF"></MyTable>
        </el-tab-pane>
        <el-tab-pane label="倾角监测">
          <MyTable :dataSource="dataSourceL" index stripe :columns="columnsL" border :loading="loadingL"></MyTable>
        </el-tab-pane>
        <el-tab-pane label="水平位移监测">
          <MyTable :dataSource="dataSourceH" index stripe :columns="columnsH" border :loading="loadingH"></MyTable>
        </el-tab-pane>

        <el-tab-pane label="沉降监测V3">
          <MyTable :dataSource="dataSourceVV3" index stripe :columns="columnsV" border :loading="loadingVV3"></MyTable>
        </el-tab-pane>
        <el-tab-pane label="轴力监测V3">
          <MyTable :dataSource="dataSourceFV3" index stripe :columns="columnsF" border :loading="loadingFV3"></MyTable>
        </el-tab-pane>
        <el-tab-pane label="倾角监测V3">
          <MyTable :dataSource="dataSourceLV3" index stripe :columns="columnsL" border :loading="loadingLV3"></MyTable>
        </el-tab-pane>
        <el-tab-pane label="水平位移监测V3">
          <MyTable :dataSource="dataSourceHV3" index stripe :columns="columnsH" border :loading="loadingHV3"></MyTable>
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
  </div>
</template>

<script>
import { findPointByType } from '@/api/Point.js'
import { findPointByTypeV3 } from '@/api/PointV3.js'
import MyTable from '@/components/MyTable.vue'

const columnsV = [
  {
    prop: 'monitoringType',
    label: '监测类型',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'nodeNumber',
    label: '节点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '创建日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'startingValueOne',
    label: '初始值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'warningValue',
    label: '预警值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmValue',
    label: '报警值',
    type: 'text',
    align: 'center',
  },
]
const columnsF = [
  {
    prop: 'monitoringType',
    label: '监测类型',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'nodeNumber',
    label: '节点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '创建日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'span',
    label: '量程',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'startingValueOne',
    label: '初始值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'warningValue',
    label: '预警值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmValue',
    label: '报警值',
    type: 'text',
    align: 'center',
  },
]
const columnsL = [
  {
    prop: 'monitoringType',
    label: '监测类型',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'nodeNumber',
    label: '节点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '创建日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'startingValueOne',
    label: '初始值X',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'startingValueTwo',
    label: '初始值Y',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'warningValue',
    label: '预警值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmValue',
    label: '报警值',
    type: 'text',
    align: 'center',
  },
]
const columnsH = [
  {
    prop: 'monitoringType',
    label: '监测类型',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'pointName',
    label: '测点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'nodeNumber',
    label: '节点编号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'createTime',
    label: '创建日期',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'characteristicLength',
    label: '特征长度',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'warningValue',
    label: '预警值',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'alarmValue',
    label: '报警值',
    type: 'text',
    align: 'center',
  },
]

const dataSourceV = []
const dataSourceF = []
const dataSourceL = []
const dataSourceH = []
const dataSourceVV3 = []
const dataSourceFV3 = []
const dataSourceLV3 = []
const dataSourceHV3 = []

export default {
  props: {
    projectId: {
      type: Number,
    },
  },
  components: {
    MyTable,
  },

  data() {
    return {
      type: '沉降监测',
      dataSourceV,
      columnsV,
      loadingV: true,
      dataSourceF,
      columnsF,
      loadingF: true,
      dataSourceL,
      columnsL,
      loadingL: true,
      dataSourceH,
      columnsH,
      loadingH: true,
      dataSourceVV3,
      dataSourceFV3,
      dataSourceLV3,
      dataSourceHV3,
    }
  },
  methods: {
    // 查询任务所有测点
    async findAllPointFunV() {
      const { data: res } = await findPointByType(this.$route.params.projectId, '沉降监测')
      this.dataSourceV = res.data
      this.dataSourceV.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingV = false
    },
    async findAllPointFunF() {
      const { data: res } = await findPointByType(this.$route.params.projectId, '轴力监测')
      this.dataSourceF = res.data
      this.dataSourceF.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingF = false
    },
    async findAllPointFunL() {
      const { data: res } = await findPointByType(this.$route.params.projectId, '倾角监测')
      this.dataSourceL = res.data
      this.dataSourceL.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingL = false
    },
    async findAllPointFunH() {
      const { data: res } = await findPointByType(this.$route.params.projectId, '位移监测')
      this.dataSourceH = res.data
      this.dataSourceH.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingH = false
    },

    async findAllPointFunVV3() {
      const { data: res } = await findPointByTypeV3(this.$route.params.projectId, '沉降监测')
      this.dataSourceVV3 = res.data
      this.dataSourceVV3.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingV = false
    },
    async findAllPointFunFV3() {
      const { data: res } = await findPointByTypeV3(this.$route.params.projectId, '轴力监测')
      this.dataSourceFV3 = res.data
      this.dataSourceFV3.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingF = false
    },
    async findAllPointFunLV3() {
      const { data: res } = await findPointByTypeV3(this.$route.params.projectId, '倾角监测')
      this.dataSourceLV3 = res.data
      this.dataSourceLV3.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingL = false
    },
    async findAllPointFunHV3() {
      const { data: res } = await findPointByTypeV3(this.$route.params.projectId, '位移监测')
      this.dataSourceHV3 = res.data
      this.dataSourceHV3.forEach((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : '无'
      })
      console.log(res.data)
      this.loadingH = false
    },
  },

  created() {
    this.findAllPointFunV()
    this.findAllPointFunF()
    this.findAllPointFunL()
    this.findAllPointFunH()
    this.findAllPointFunVV3()
    this.findAllPointFunFV3()
    this.findAllPointFunLV3()
    this.findAllPointFunHV3()
  },
  mounted() {
    //  react uni-app es6 ts
  },
}
</script>

<style scoped lang="scss">
.pointConfig {
  width: 100%;

  height: 100%;
  .el-scrollbar {
    height: 100%;
  }
  .el-scrollbar ::v-deep.el-scrollbar__wrap {
    overflow: scroll;
    height: 100% !important;
    overflow-x: hidden !important;
    padding: 10px 15px;
    padding-bottom: 20px;
  }
  ::v-deep td {
    height: 50px !important;
    padding: 0 !important;
    // background-color: #004c6e;
  }
  ::v-deep th {
    height: 50px;
    padding: 0 !important;
    background-color: #fafafa;
  }
}
</style>
